/* HTML: <div class="loader"></div> */
.loader {
  width: 125px;
  aspect-ratio: .75;
  --c:no-repeat linear-gradient(#136273 0 0);
  background: 
    var(--c) 0%   100%,
    var(--c) 50%  100%,
    var(--c) 100% 100%;
  background-size: 20% 65%;
  animation: l8 1s infinite linear;
}
@keyframes l8 {
  16.67% {background-position: 0% 0%  ,50% 100%,100% 100%}
  33.33% {background-position: 0% 0%  ,50% 0%  ,100% 100%}
  50%    {background-position: 0% 0%  ,50% 0%  ,100% 0%  }
  66.67% {background-position: 0% 100%,50% 0%  ,100% 0%  }
  83.33% {background-position: 0% 100%,50% 100%,100% 0%  }
}